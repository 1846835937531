<template>
  <customer-register-form
    :loading="loading"
    v-bind="$attrs"
    @register="invokeRegister"
  >
    <base-alert
      v-if="errors.register.length > 0"
      type="error"
      class="mb-8 mt-3"
    >
      {{ $t('notification.anErrorOccurred') }}
      <ul>
        <li v-for="(error, index) in errorMessages" :key="index">
          {{ error }}
        </li>
      </ul>
    </base-alert>
  </customer-register-form>
</template>

<script>
import { useUser } from '~/composables'
import CustomerRegisterForm from '~/components/customer/login/CustomerRegisterForm'
import BaseAlert from '~/components/base/alert/BaseAlert'

export default {
  name: 'CustomerRegisterFormContainer',
  components: { BaseAlert, CustomerRegisterForm },
  setup(_, { root }) {
    const { register, loading, errors } = useUser(root)

    return {
      register,
      loading,
      errors,
    }
  },
  computed: {
    errorMessages() {
      return this.errors.register.map((error) => this.getErrorMessage(error))
    },
  },
  methods: {
    async invokeRegister(address) {
      this.errors.register = []

      this.$emit('click:register', {
        guest: address.guest,
      })

      const success = await this.register({
        ...address,
        billingAddress: {
          ...address,
        },
        storefrontUrl: this.$config.appUrl,
      })

      if (success) {
        this.$emit('success')
      }
    },

    getErrorMessage({ code }) {
      switch (code) {
        case 'VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE':
          return this.$t('backend.error.VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE')
        default:
          return this.$t('backend.error.UNKNOWN')
      }
    },
  },
}
</script>
