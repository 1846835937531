var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form"},[_vm._t("default"),_vm._v(" "),_c('v-form',[_c('v-row',{staticClass:"ma-n2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.salutation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-select',{attrs:{"items":_vm.salutationList,"label":_vm.$t('fields.salutation') + '*',"hide-details":"auto","required":"","error-messages":errors},model:{value:(_vm.form.salutationId),callback:function ($$v) {_vm.$set(_vm.form, "salutationId", $$v)},expression:"form.salutationId"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"label":_vm.$t('fields.firstname') + '*',"autocomplete":"given-name","hide-details":"auto","error-messages":errors},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.firstName"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"label":_vm.$t('fields.lastname') + '*',"autocomplete":"last-name","hide-details":"auto","error-messages":errors},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.lastName"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.email'),"rules":"required|email","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"autocomplete":"email","hide-details":"auto","type":"email","label":_vm.$t('fields.email') + '*',"error-messages":errors},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.address'),"rules":"required|street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"hide-details":"auto","label":_vm.$t('fields.address') + '*',"autocomplete":"shipping street-address","error-messages":errors},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.street"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.suffix')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"label":_vm.$t('fields.suffix'),"hide-details":"auto","error-messages":errors},model:{value:(_vm.form.additionalAddressLine1),callback:function ($$v) {_vm.$set(_vm.form, "additionalAddressLine1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.additionalAddressLine1"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.zipCode'),"rules":_vm.zipcodeRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"label":_vm.$t('fields.zipCode') + '*',"autocomplete":"shipping postal-code","hide-details":"auto","error-messages":errors},model:{value:(_vm.form.zipcode),callback:function ($$v) {_vm.$set(_vm.form, "zipcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.zipcode"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"label":_vm.$t('fields.city') + '*',"autocomplete":"shipping locality","hide-details":"auto","error-messages":errors},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.city"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-select',{attrs:{"items":_vm.countryList,"label":_vm.$t('fields.country') + '*',"autocomplete":"shipping country","hide-details":"auto","error-messages":errors},model:{value:(_vm.form.countryId),callback:function ($$v) {_vm.$set(_vm.form, "countryId", $$v)},expression:"form.countryId"}})]}}])})],1),_vm._v(" "),(_vm.withGuest)?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('fields.createUserAccount'),"false-value":true,"true-value":false,"dense":"","ripple":false,"hide-details":"auto"},model:{value:(_vm.form.guest),callback:function ($$v) {_vm.$set(_vm.form, "guest", $$v)},expression:"form.guest"}})],1):_vm._e(),_vm._v(" "),(!_vm.form.guest)?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('fields.password'),"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-text-field',{attrs:{"label":_vm.$t('fields.password') + '*',"type":"password","autocomplete":"new-password","hide-details":"auto","hint":_vm.$t('notification.passwordLength'),"error-messages":errors},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,false,3016466819)})],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"text-body-5 px-2 pt-3",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.$t('fields.mandatoryField')))]),_vm._v(" "),_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('base-button',{staticClass:"mt-6 mx-auto checkout-primary white--text",attrs:{"large":"","rounded":"","dynamic":"","loading":_vm.loading},on:{"click":_vm.invokeSubmit}},[_vm._v(_vm._s(_vm.form.guest ? _vm.$t('fields.orderAsGuest') : _vm.$t('actions.register'))+"\n        ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }