<template>
  <validation-observer ref="form">
    <slot></slot>
    <v-form>
      <v-row no-gutters class="ma-n2">
        <v-col cols="12" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.salutation')"
            rules="required"
          >
            <base-input-select
              v-model="form.salutationId"
              :items="salutationList"
              :label="$t('fields.salutation') + '*'"
              hide-details="auto"
              required
              :error-messages="errors"
            ></base-input-select>
          </validation-provider>
        </v-col>
        <v-col cols="6" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.firstname')"
            rules="required"
          >
            <base-input-text-field
              v-model.trim="form.firstName"
              :label="$t('fields.firstname') + '*'"
              autocomplete="given-name"
              hide-details="auto"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="6" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.lastname')"
            rules="required"
          >
            <base-input-text-field
              v-model.trim="form.lastName"
              :label="$t('fields.lastname') + '*'"
              autocomplete="last-name"
              hide-details="auto"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.email')"
            rules="required|email"
            mode="lazy"
          >
            <base-input-text-field
              v-model.trim="form.email"
              autocomplete="email"
              hide-details="auto"
              type="email"
              :label="$t('fields.email') + '*'"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.address')"
            rules="required|street"
          >
            <base-input-text-field
              v-model.trim="form.street"
              hide-details="auto"
              :label="$t('fields.address') + '*'"
              autocomplete="shipping street-address"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" class="pa-2">
          <validation-provider v-slot="{ errors }" :name="$t('fields.suffix')">
            <base-input-text-field
              v-model.trim="form.additionalAddressLine1"
              :label="$t('fields.suffix')"
              hide-details="auto"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="4" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.zipCode')"
            :rules="zipcodeRule"
          >
            <base-input-text-field
              v-model.trim="form.zipcode"
              :label="$t('fields.zipCode') + '*'"
              autocomplete="shipping postal-code"
              hide-details="auto"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="8" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.city')"
            rules="required"
          >
            <base-input-text-field
              v-model.trim="form.city"
              :label="$t('fields.city') + '*'"
              autocomplete="shipping locality"
              hide-details="auto"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.country')"
            rules="required"
          >
            <base-input-select
              v-model="form.countryId"
              :items="countryList"
              :label="$t('fields.country') + '*'"
              autocomplete="shipping country"
              hide-details="auto"
              :error-messages="errors"
            ></base-input-select>
          </validation-provider>
        </v-col>

        <v-col v-if="withGuest" cols="12" class="pa-2">
          <v-checkbox
            v-model="form.guest"
            :label="$t('fields.createUserAccount')"
            :false-value="true"
            :true-value="false"
            dense
            :ripple="false"
            hide-details="auto"
          ></v-checkbox>
        </v-col>
        <v-col v-if="!form.guest" cols="12" class="pa-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.password')"
            rules="required|min:6"
          >
            <base-input-text-field
              v-model="form.password"
              :label="$t('fields.password') + '*'"
              type="password"
              autocomplete="new-password"
              hide-details="auto"
              :hint="$t('notification.passwordLength')"
              :error-messages="errors"
            ></base-input-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" class="text-body-5 px-2 pt-3">{{
          $t('fields.mandatoryField')
        }}</v-col>
        <v-col cols="12" class="px-2 py-0">
          <base-button
            large
            rounded
            dynamic
            class="mt-6 mx-auto checkout-primary white--text"
            :loading="loading"
            @click="invokeSubmit"
            >{{
              form.guest ? $t('fields.orderAsGuest') : $t('actions.register')
            }}
          </base-button>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { useCountries, useSalutations } from '~/composables'
import BaseInputSelect from '~/components/base/input/BaseInputSelect'
import BaseInputTextField from '~/components/base/input/BaseInputTextField'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CustomerRegisterForm',
  components: {
    BaseButton,
    BaseInputTextField,
    BaseInputSelect,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    withGuest: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const { getSalutations } = useSalutations(root)
    const { getCountries } = useCountries(root)

    return {
      getSalutations,
      getCountries,
    }
  },
  data() {
    return {
      form: {
        salutationId: undefined,
        firstName: undefined,
        lastName: undefined,
        street: undefined,
        additionalAddressLine1: undefined,
        zipcode: undefined,
        city: undefined,
        countryId: undefined,
        email: undefined,
        guest: this.withGuest, // default ist Gast
        password: undefined,
      },
    }
  },
  computed: {
    salutationList() {
      return this.getSalutations.map((salutations) => ({
        text: salutations?.translated?.displayName,
        value: salutations.id,
      }))
    },
    countryList() {
      return this.getCountries.map((country) => ({
        text: country?.translated?.name,
        value: country.id,
      }))
    },
    countryISO() {
      return this.getCountries?.find(({ id }) => id === this.form.countryId)
        ?.iso
    },
    zipcodeRule() {
      return ['required', 'zipcode:' + this.countryISO].join('|')
    },
  },
  watch: {
    countryList: {
      handler(countries) {
        if (!this.form.countryId && countries) {
          if (countries.length === 1) {
            this.form.countryId = countries[0].value
          } else if (this.$i18n.locale === 'de-DE') {
            const german = countries.find(
              (country) => country.text === 'Deutschland'
            )

            if (german) {
              this.form.countryId = german.value
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    async invokeSubmit() {
      if (!(await this.$refs.form.validate())) {
        return
      }

      this.$emit('register', {
        ...this.form,
      })
    },
  },
}
</script>
